import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import PathRoute from 'src/common/PathRoute';
import Utils from 'src/common/Utils';
import Button from 'src/components/Button';
import getLocalStorage from 'src/hooks/useGetLocalStorage';
import SearchBox1 from 'src/components/SearchMaps/SearchBox1';

import { useDispatch, useSelector } from 'react-redux';
import { headerActionType } from 'src/redux/actions/actionTypes';
import isEmpty from 'lodash/isEmpty';
import useGetWidth from 'src/hooks/useGetWidth';
import { PopupButton } from '@typeform/embed-react';
import Constants from 'src/common/Constants';

function BannerTop(props) {
    const { listSite } = props;
    const dispatch = useDispatch();
    const [textSearch, setTextSearch] = useState('');
    const { defaultTypeform } = useSelector((state) => state.homeReducer);
    const [searchResult, setSearchResult] = useState({});
    const history = useRouter();
    const defaultAcademy = getLocalStorage();
    const isMobile = useGetWidth() <= 768;
    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    useEffect(() => {
        if (!isEmpty(searchResult)) {
            dispatch({
                type: headerActionType.CHANGE_LOCATION,
                data: { ...searchResult },
            });

            setTextSearch({});
        }
    }, [searchResult]);

    const renderButton = () => {
        if (!isEmpty(listSite)) {
            if (Object?.values?.(listSite).length === 1) {
                const title = `${
                    listSite[0]?.ms_trial === 1 ? 'BOOK A TRIAL SESSION' : 'TRY A FREE SESSION'
                } `;
                return (
                    <div className="box-button">
                        <Button
                            idTypeForm={
                                listSite[0]?.ms_use_typeform === 1
                                    ? listSite[0]?.ms_typeform_id
                                    : null
                            }
                            title={title}
                            onClick={() => {
                                global.bookTraining = {
                                    siteSelected: listSite[0],
                                };

                                history.push(
                                    PathRoute.BookTrialTrainingSuccessWithAlias(
                                        country,
                                        listSite[0].ms_alias,
                                    ),
                                );
                            }}
                        />
                    </div>
                );
            }
            return;
        }

        if (defaultAcademy) {
            const title = `${
                defaultAcademy && defaultAcademy.ms_trial === 1
                    ? 'BOOK A TRIAL SESSION'
                    : 'TRY A FREE SESSION'
            } `;

            return (
                <div className="box-button">
                    <Button
                        idTypeForm={
                            defaultTypeform.use_typeform === 1
                                ? defaultTypeform.default_typeform_id
                                : null
                        }
                        title={title}
                        onClick={() => {
                            global.bookTraining = {
                                siteSelected: defaultAcademy,
                            };

                            history.push(
                                PathRoute.BookTrialTrainingSuccessWithAlias(
                                    country,
                                    defaultAcademy.ms_alias,
                                ),
                            );
                        }}
                    />
                </div>
            );
        }

        return (
            <div className="box-pin">
                <span className="text-form ">Find your nearest academy:</span>
                <SearchBox1
                    setSearchResult={setSearchResult}
                    textSearch={textSearch}
                    setTextSearch={setTextSearch}
                    listSite={listSite}
                    country={country}
                />
            </div>
        );
    };

    return (
        <div
            className="banner-top"
            style={{
                backgroundImage: `url(${Utils?.getThumb(
                    isMobile
                        ? props?.bannerTop?.cfg_mobileBanner
                        : props?.bannerTop?.cfg_image,
                )}) `,
            }}>
            <div className="container">
                <h1>{props.bannerTop.cfg_title}</h1>
                <div className="box-text">
                    <div dangerouslySetInnerHTML={{ __html: props.bannerTop.cfg_des }}></div>
                    {renderButton()}
                </div>
            </div>
        </div>
    );
}

export default BannerTop;
